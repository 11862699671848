// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { formatISODateTimeZone } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";
import { useSentHomeRequests } from "@src/appV2/Agents/api/useSentHomeRequests";
import { invalidateBookedCases } from "@src/appV2/ExperimentalHomeHealth/BookedCases/api/invalidateBookedCases";
import { useHomeHealthVisibility } from "@src/appV2/ExperimentalHomeHealth/BookedCases/api/useHomeHealthVisibility";
import { useOpenNegotiationsCount } from "@src/appV2/Negotiations/api/useOpenNegotiationsCount";
import { useExtraWorkedTimeRequests } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useExtraWorkedTimeRequests";
import {
  type Bookings,
  InterviewStatus,
  transformResponseIntoInterview,
  useFetchPaginatedInterviews,
} from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { useCheckIsOnCallWorkplace } from "@src/appV2/Shifts/Shift/useCheckIsOnCallWorkplace";
import { useWorkerPendingShiftInvites } from "@src/appV2/Shifts/ShiftInvites/api/useWorkerPendingShiftInvites";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQueryClient } from "@tanstack/react-query";
import {
  addDays,
  addHours,
  compareAsc,
  isBefore,
  parseISO,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import { useCurrentDate } from "../../../lib/utils/useCurrentDate";
import { useIsJobInterviewsEnabled } from "../../Placements/useIsJobInterviewsEnabled";
import { CurrentShiftStage } from "../../Shift/constants";
import { getFormattedShiftPayBreakdown } from "../../Shift/getFormattedShiftPayBreakdown";
import { getShiftCurrentStage } from "../../Shift/WorkerShift/getShiftCurrentStage";
import { useGetWorkerShifts } from "../api/useGetWorkerShifts";
import { useGetBookingRequests } from "../BookingRequests/api/useGetBookingRequests";
import { useWorkerHasBookingRequestsEnabled } from "../BookingRequests/api/useWorkerHasBookingRequestsEnabled";
import { BookingRequestStatus } from "../BookingRequests/types";
import { invalidateOnCallOfferedShifts } from "../OnCallOffers/useOnCallOfferedShifts";
import { useWorkerUnverifiedShifts } from "../UnverifiedShifts/useWorkerUnverifiedShifts";

function isHighlightedBooking(workerTimezone: string, booking?: Bookings): boolean {
  if (!isDefined(booking)) {
    return false;
  }

  if (booking.type === "shift") {
    const shift = booking.data;
    const currentShiftState = getShiftCurrentStage(shift);

    if (currentShiftState !== CurrentShiftStage.NOT_STARTED) {
      return true;
    }

    const now = new Date();
    const next12Hours = addHours(now, 12);
    const bookingStart = parseISO(booking.data.start);
    return isBefore(bookingStart, next12Hours);
  }

  if (booking.type === "interview") {
    const interview = booking.data;
    const nowInWorkerTimezone = utcToZonedTime(new Date(), workerTimezone);
    const next12Hours = addHours(nowInWorkerTimezone, 12);
    return isBefore(interview.start, next12Hours);
  }

  return false;
}

function splitOutHighlightedBooking(bookings: Bookings[], workerTimezone: string) {
  const highlightedBooking =
    bookings.length > 0 && isHighlightedBooking(workerTimezone, bookings[0])
      ? bookings[0]
      : undefined;
  const scheduledBookings = isDefined(highlightedBooking) ? bookings.slice(1) : bookings;
  return {
    highlightedBooking,
    scheduledBookings,
  };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function useWorkerBookingsData() {
  const worker = useDefinedWorker();
  const queryClient = useQueryClient();

  const { shouldShowHomeHealth, isHomeHealthEnabled } = useHomeHealthVisibility();
  const isJobInterviewsEnabled = useIsJobInterviewsEnabled();

  // Fetch shifts
  const {
    shifts,
    isLoading: isLoadingShifts,
    refetch: refetchShifts,
  } = useGetWorkerShifts({ worker });

  // Fetch shift invites
  const {
    data: shiftInvites,
    refetch: refetchShiftInvites,
    isLoading: isLoadingShiftInvites,
  } = useWorkerPendingShiftInvites();

  // Fetch unverified shifts
  const {
    data: unverifiedShiftsData,
    refetch: refetchUnverifiedShifts,
    isLoading: isLoadingUnverifiedShifts,
  } = useWorkerUnverifiedShifts();

  // Fetch negotiations
  const {
    data: openNegotiationsCount,
    refetch: refetchNegotiations,
    isLoading: isLoadingNegotiations,
  } = useOpenNegotiationsCount();

  // Fetch sent home requests
  const {
    data: sentHomeRequestsData,
    refetch: refetchSentHome,
    isLoading: isLoadingSentHome,
  } = useSentHomeRequests();

  // Fetch extra worked time requests
  const {
    data: extraWorkedTimeRequestsData,
    refetch: refetchExtraWorked,
    isLoading: isLoadingExtraWorked,
  } = useExtraWorkedTimeRequests();

  // Fetch booking requests
  const areBookingRequestsEnabled = useWorkerHasBookingRequestsEnabled();
  const {
    data: bookingRequestsData,
    refetch: refetchBookingRequests,
    isLoading: isLoadingBookingRequests,
  } = useGetBookingRequests(
    {
      filter: { status: { eq: BookingRequestStatus.PENDING }, "worker.id": { eq: worker.userId } },
    },
    { enabled: areBookingRequestsEnabled }
  );

  // Fetch interviews
  const {
    isLoading: isLoadingInterviews,
    data: interviewsDataWithPages,
    refetch: refetchInterviews,
  } = useFetchPaginatedInterviews(
    {
      filter: {
        workerId: worker.userId,
        status: InterviewStatus.BOOKED,
        start: {
          gt: formatISODateTimeZone(setMilliseconds(setSeconds(setMinutes(new Date(), 0), 0), 0), {
            timeZone: "UTC",
          }),
        },
        end: {
          lt: formatISODateTimeZone(
            setMilliseconds(setSeconds(setMinutes(addDays(new Date(), 3), 0), 0), 0),
            { timeZone: "UTC" }
          ),
        },
      },
    },
    {
      enabled: isDefined(worker.userId) && isJobInterviewsEnabled,
    }
  );

  // Transform interview data
  const interviewsData = interviewsDataWithPages?.pages.flatMap((page) =>
    page.data.map((interview) =>
      transformResponseIntoInterview(interview, page.included ?? [], worker.tmz ?? "")
    )
  );

  // Filter shifts to show only: 1) No sent home requests 2) Not deleted/verified
  // 3) Either still active (current time < end time) or already clocked into
  const currentDate = useCurrentDate(60_000); // Update every minute
  const displayableShifts = shifts
    .filter((shift) => !sentHomeRequestsData?.some((request) => request.shift._id === shift._id))
    .filter((shift) => {
      if (!isDefined(shift)) {
        return false;
      }

      // Filter out deleted or verified shifts
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const isDeletedOrVerified = shift.deleted || shift.verified;
      if (isDeletedOrVerified) {
        return false;
      }

      const isShiftOngoing = isBefore(currentDate, parseISO(shift.end));
      const isUserClockedIn = isDefined(shift.clockInOut?.start);

      // Keep active shifts and shifts where the user clocked in
      return isShiftOngoing || isUserClockedIn;
    });

  // Combine shifts and interviews
  const bookings: Bookings[] = [
    ...displayableShifts.map((shift) => ({ type: "shift" as const, data: shift })),
    ...(interviewsData ?? []).map((interview) => ({ type: "interview" as const, data: interview })),
  ].sort((a, b) => {
    const aDate = parseISO(
      a.type === "shift"
        ? a.data.start
        : zonedTimeToUtc(a.data.start, worker.tmz ?? "").toISOString()
    );
    const bDate = parseISO(
      b.type === "shift"
        ? b.data.start
        : zonedTimeToUtc(b.data.start, worker.tmz ?? "").toISOString()
    );
    return compareAsc(aDate, bDate);
  });

  // Split out highlighted booking
  let { highlightedBooking, scheduledBookings } = splitOutHighlightedBooking(bookings, worker.tmz);

  // remove on call shifts from highlighted booking
  const checkIsOnCallShift = useCheckIsOnCallWorkplace();
  if (
    isDefined(highlightedBooking) &&
    highlightedBooking.type === "shift" &&
    checkIsOnCallShift(highlightedBooking.data.facility.userId)
  ) {
    scheduledBookings.unshift(highlightedBooking);
    highlightedBooking = undefined;
  }

  // Calculate shift pay breakdown for highlighted booking
  const highlightedShiftPayBreakdown =
    highlightedBooking?.type === "shift"
      ? getFormattedShiftPayBreakdown(
          highlightedBooking?.data.offer?.pay.value,
          highlightedBooking?.data.finalPay,
          highlightedBooking?.data.originalAmount
        )
      : undefined;

  const unverifiedShifts = unverifiedShiftsData?.response ?? [];
  const hasUpcomingShifts = bookings.length > 0;

  // Note: interviews are in loading state when isJobInterviewsEnabled is false, so for true loading state both must be checked for true
  const isLoading = isLoadingShifts || (isJobInterviewsEnabled && isLoadingInterviews);
  const showEmptyShiftState =
    !shouldShowHomeHealth && !isLoading && bookings.length === 0 && !isDefined(highlightedBooking);

  // Function to refresh all data
  async function refreshAllData() {
    await Promise.all([
      refetchShifts(),
      refetchShiftInvites(),
      refetchUnverifiedShifts(),
      refetchNegotiations(),
      refetchSentHome(),
      refetchExtraWorked(),
      invalidateOnCallOfferedShifts(queryClient),
      isJobInterviewsEnabled ? refetchInterviews() : Promise.resolve(),
      isHomeHealthEnabled ? invalidateBookedCases(queryClient) : Promise.resolve(),
      areBookingRequestsEnabled ? refetchBookingRequests() : Promise.resolve(),
    ]);
  }

  return {
    // Booking data
    bookings,
    scheduledBookings,
    highlightedBooking,
    highlightedShiftPayBreakdown,
    hasUpcomingShifts,

    // Loading states
    isLoading,
    isLoadingShiftInvites,
    isLoadingUnverifiedShifts,
    isLoadingNegotiations,
    isLoadingSentHome,
    isLoadingExtraWorked,
    isLoadingBookingRequests,

    // Callout data
    shiftInvites,
    unverifiedShifts,
    openNegotiationsCount,
    sentHomeRequestsData,
    extraWorkedTimeRequestsData,
    bookingRequestsData,

    // Feature flags
    shouldShowHomeHealth,
    isJobInterviewsEnabled,
    areBookingRequestsEnabled,

    // UI state
    showEmptyShiftState,

    // Actions
    refreshAllData,
  };
}
