import { RootPaths } from "@src/appV2/App/paths";
import { MY_SHIFTS_PATH } from "@src/appV2/Shifts/MyShifts";

export const UNVERIFIED_SHIFTS_PATH = `${MY_SHIFTS_PATH}/unverifiedShifts`;
export const UNVERIFIED_SHIFTS_FULL_PATH = `${RootPaths.APP_V2_HOME}/${MY_SHIFTS_PATH}/unverifiedShifts`;

export const BREAK_PAYMENT_REQUESTS_PATH = `${MY_SHIFTS_PATH}/break-payment-requests`;
export const BREAK_PAYMENT_REQUESTS_FULL_PATH = `${RootPaths.APP_V2_HOME}/${BREAK_PAYMENT_REQUESTS_PATH}`;

export const SHIFT_INVITES_PATH = `${MY_SHIFTS_PATH}/shiftInvites`;
export const SHIFT_INVITES_FULL_PATH = `${RootPaths.APP_V2_HOME}/${SHIFT_INVITES_PATH}`;

export const BOOKING_REQUESTS_PATH = `${MY_SHIFTS_PATH}/bookingRequests`;
export const BOOKING_REQUESTS_FULL_PATH = `${RootPaths.APP_V2_HOME}/${BOOKING_REQUESTS_PATH}`;

export const SENT_HOME_REQUESTS_PATH = `${MY_SHIFTS_PATH}/sent-home-requests`;
export const SENT_HOME_REQUESTS_FULL_PATH = `${RootPaths.APP_V2_HOME}/${SENT_HOME_REQUESTS_PATH}`;

export const CREATE_SENT_HOME_REQUEST_PATH = `${MY_SHIFTS_PATH}/sent-home-request/create/:shiftId`;

export const SHIFT_DETAILS_PATH = `${MY_SHIFTS_PATH}/shift-details/:shiftId`;
export const SHIFT_DETAILS_FULL_PATH = `${RootPaths.APP_V2_HOME}/${SHIFT_DETAILS_PATH}`;

export const SHIFT_DETAILS_TIMEKEEPING_PATH = `/timekeeping`;
export const SHIFT_DETAILS_TIMEKEEPING_FULL_PATH = `${SHIFT_DETAILS_PATH}${SHIFT_DETAILS_TIMEKEEPING_PATH}`;

export const BOOKING_REQUEST_DETAILS_PATH = `${BOOKING_REQUESTS_PATH}/:bookingRequestId`;
export const BOOKING_REQUEST_DETAILS_FULL_PATH = `${RootPaths.APP_V2_HOME}/${BOOKING_REQUEST_DETAILS_PATH}`;
