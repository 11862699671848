import { isDefined } from "@clipboard-health/util-ts";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { getShiftCurrentStage } from "@src/appV2/redesign/Shift/WorkerShift/getShiftCurrentStage";
import { HighlightedWorkerShiftCard } from "@src/appV2/redesign/Shift/WorkerShift/HighlightedCard";
import { HighlightedWorkerInterviewCard } from "@src/appV2/redesign/Shift/WorkerShift/HighlightedWorkerInterviewCard";
import { type Bookings } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { generatePath } from "react-router-dom";

import { type WorkersForOverlappingShiftSlots } from "../../WorkWithFriends/hooks/useGetWorkersForOverlappingShiftSlots";
import { SHIFT_DETAILS_FULL_PATH } from "../path";

interface HighlightedBookingSectionProps {
  highlightedBooking?: Bookings;
  workersForOverlappingShiftSlot?: WorkersForOverlappingShiftSlots;
  shiftPayBreakdown?: {
    hourlyPay?: string;
    totalPay?: string;
  };
  currentDate: Date;
  isWorkerShiftsTimekeepingPageRedesignEnabled: boolean;
}

export function HighlightedBookingSection(props: HighlightedBookingSectionProps) {
  const {
    highlightedBooking,
    shiftPayBreakdown,
    currentDate,
    isWorkerShiftsTimekeepingPageRedesignEnabled,
    workersForOverlappingShiftSlot,
  } = props;
  if (!isDefined(highlightedBooking)) {
    return null;
  }

  if (highlightedBooking.type === "shift") {
    return (
      <HighlightedWorkerShiftCard
        href={
          isWorkerShiftsTimekeepingPageRedesignEnabled
            ? generatePath(SHIFT_DETAILS_FULL_PATH.replace(":shiftId", highlightedBooking.data._id))
            : generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, {
                shiftId: highlightedBooking.data._id,
              })
        }
        workplaceName={highlightedBooking.data.facility.name}
        workersForOverlappingShiftSlot={workersForOverlappingShiftSlot}
        workplaceTimezone={highlightedBooking.data.facility.tmz ?? ""}
        shiftStart={highlightedBooking.data.start}
        shiftEnd={highlightedBooking.data.end}
        shiftPosition={highlightedBooking.data.agentReq}
        shiftHourlyPay={shiftPayBreakdown?.hourlyPay}
        shiftTotalPay={shiftPayBreakdown?.totalPay}
        shiftStage={getShiftCurrentStage(highlightedBooking.data)}
        shiftTimeSlot={shiftPeriodToTimeSlot[highlightedBooking.data.name]}
        currentDate={currentDate}
      />
    );
  }

  if (highlightedBooking.type === "interview") {
    return (
      <HighlightedWorkerInterviewCard
        interviewId={highlightedBooking.data.id}
        start={highlightedBooking.data.start}
        end={highlightedBooking.data.end}
        status={highlightedBooking.data.status}
        placementId={highlightedBooking.data.placementId}
        workerTypes={highlightedBooking.data.placement?.workerTypes ?? []}
        workplaceName={highlightedBooking.data.workplace?.name ?? ""}
        currentDate={currentDate}
      />
    );
  }

  return null;
}
