import { Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { Button } from "@src/appV2/redesign/components/Button";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

import { formatTime } from "../../utils/formatTime";

export interface ShiftUnavailablePageProps {
  shift: Shift | undefined;
  workerId: string | undefined;
  error: unknown | undefined;
}

export function ShiftUnavailablePage(props: ShiftUnavailablePageProps): JSX.Element {
  const { shift, workerId, error } = props;
  const history = useHistory();

  let errorMessage = "There was an error while loading details of your shift, please try again.";
  if (!error) {
    errorMessage = "This shift is no longer available. Click below to go to my shifts page.";

    const isAssignedToSameUser = isDefined(shift?.agentId) && shift?.agentId === workerId;
    const shiftStart = shift?.start;
    const facilityName = shift?.facility?.name;
    const timeZone = shift?.facility?.tmz;

    if (
      !isAssignedToSameUser &&
      isDefined(shiftStart) &&
      isDefined(timeZone) &&
      isDefined(facilityName)
    ) {
      errorMessage = `We found someone else to work the ${formatTime(
        parseISO(shiftStart),
        timeZone
      )} shift at ${facilityName}.`;
    }
  }

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Error"
          leftCta={<BackButtonLink alwaysUseDefaultBackTo defaultBackTo="/home/myShifts" />}
        />
      }
    >
      <Stack justifyContent="center" alignItems="center" sx={{ height: "85vh" }}>
        <Title component="h1" align="center" sx={{ marginBottom: 2 }}>
          We can&apos;t load your shift.
        </Title>
        <Text align="center" sx={{ marginBottom: 4 }}>
          {errorMessage}
        </Text>
        <Button
          variant="contained"
          onClick={() => {
            history.replace("/home/myShifts");
          }}
        >
          Go to My Shifts
        </Button>
      </Stack>
    </PageWithHeader>
  );
}
